import VendorsRequestBuilders from "../RequestBuilders/vendors";
class Vendors {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.vendorRequestBuilder = new VendorsRequestBuilders(Util);
  }
  getVendors = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.VENDORS + "/getvendors", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getVendor = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/getvendor/${data.vendorId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getAllVendor = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/getAllVendors`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createVendor = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.VENDORS;
    const reqData = this.vendorRequestBuilder.CreateVendor(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getServiceAreas = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      "/getservicearea";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  uploadFleetDocument = (file, ownerId, glCode) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      "/document/upload";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, file)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateServiceVendor = (data, vendorData) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/${vendorData.vendorId}`;
    let reqData = this.vendorRequestBuilder.UpdateVendor(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createFleetVendor = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      "/register";
    const reqData = this.vendorRequestBuilder.CreateFleetVendor(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createExistingFleetVendor = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      "/addExistingFV";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createsmsverification = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.VENDORS + "/smsverification",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createresendverification = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/resendVerificationCode/${data.ownerId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFleetVendors = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.VENDORS + "/getfleetvendors",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //get all fleet vendors list for search dropdown
  getAllFleetVendors = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.VENDORS + `/getfleetvendorlist?searchText=${data}`
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //http://localhost:3080/vendors/getfleetvendorlist?searchText=nani
  getfleetvendorlist = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.VENDORS + "/getfleetvendorlist",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getFleetVendor = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/getfleetvendorbyid?ownerId=${data.ownerId}&vendorCompanyId=${data.vendorId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateFleetVendor = (companyId) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/upadateVendorCompany/${companyId.companyId}`;

    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url,companyId?.data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updatevendorstatus = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.VENDORS + `/vendorstatusupdate/${data.vendorId}`,
        { status: data.status === 0 ? "ACTIVE" : "INACTIVE" }
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getVendorCompanies = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      "/getfleetvendorcompanylist";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTransferVendor = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vehicles/details/${data}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  transferVendor = (newData, dispatch_remarks) => {
    // let reqData = this.VendorsRequestBuilders.createVendorRequest(data);
    // reqData.reqEmails = data.reqEmails;
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/registertransfer`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, {
          ...newData,
          dispatch_remarks: dispatch_remarks,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getVechileRequestVerification = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/request/list?startDate=${data.startDate}&endDate=${data.endDate}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  getNewVechileList = (routeId) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/vehrequest/list?batchId=${routeId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  postStatusBatchId = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/batch/update`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  getCtrlVechileList = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/request/list/`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };

  removeVendor = (newData, dispatch_remarks) => {
    // let reqData = this.VendorsRequestBuilders.createVendorRequest(data);
    // reqData.reqEmails = data.reqEmails;
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/registerremove`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, {
          ...newData,
          // reqEmails: emails,
          dispatch_remarks: dispatch_remarks,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  postCtrlStatusBatchId = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/vehrequest/list?batchId=${data}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  ctrlStatusBatchId = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/batch/update`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };

  sendForApprovalEmails = () => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/authuser/5`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
   getResonsSubcategory= (id) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/owner/getReasonSubCategory?reason_cat_id=${id?id:''}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateHoldingDelayReason = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/owner/updateReason`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  getReasonCategories= () => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/owner/getReasonCategories`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  
  addReasonCategory = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/owner/addReasonCategory`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  addReasonSubCategory = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/owner/addReasonSubCategory`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateReasonSubCategory = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/owner/updateReasonSubCategory`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };


  getBanks = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/invoices/getbanks`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };

  getFleetVendorsPaymentsDetails = (dataObj) => {
    let s = "";
    Object.keys(dataObj).map((i) => {
      s += `${i}=${dataObj[i]}&`;
    });
    let formateData = s.slice(0, -1);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/fleetVendorPayments/getVendorDetails?${formateData}`;
      // `/fleetVendorPayments/getVendorDetails?${formateData}&${`skip=${dataObj.skip}`}&${`limit=${dataObj.limit}`}`;

    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  //http://localhost:3080/fleetVendorPayments/getVendorOrders
  getFleetVendorsDues = (data, id) => {
    const url =
      data.skip && data.limit
        ? process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
          this.Util.queryParamsFromObj(
            `/fleetVendorPayments/getVendorOrders?comp_id=${id}&${`skip=${data.skip}`}&${
              data.limit
            }`
          )
        : process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
          this.Util.queryParamsFromObj(
            `/fleetVendorPayments/getVendorOrders?comp_id=${id}`
          );
    // `/fleetVendorPayments/getVendorOrders?comp_id=${id}`,data

    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  exchangeVehicle = (newData, dispatch_remarks) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/registerexchange`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, {
          ...newData,
          dispatch_remarks: dispatch_remarks,
        })
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };

  uploadFleetvendorDocument = (data, formData) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/documents/${data}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, formData)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  generateBulkPaymentsVocher = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      "/fleetVendorPayments/bulkGenerateVoucher";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTransporterDetails = (data) => {
    let s = "";
    Object.keys(data).map((i) => {
      s += `${i}=${data[i]}&&`;
    });
    let formateData = s.slice(0, -2);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.VENDORS +
      `/request/list?${formateData}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };

  getFleetVendorExport = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.VENDORS + "/exportVendor");
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getVendorPayment = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.VENDORS + `/tempExportRequest/${data}`);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  generateDuePaymentVocher = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      "/fleetVendorPayments/generateVoucher";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getLedgersDetails = (dataObj, id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/fleetVendorPayments/getLedgerDetails?id=${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };

  getVocherDetails = (data) => {
    let s = "";
    Object.keys(data).map((i) => {
      s += `${i}=${data[i]}&`;
    });
    let formateData = s.slice(0, -1);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/fleetVendorPayments/getVendorDetails?${formateData}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  generatePaymentVocherReceipt = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/fleetVendorPayments/FleetVendorPaymentList?id=${data.voucherId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFleetVendorsData = (data) => {
    let s = "";
    Object.keys(data).map((i) => {
      s += `${i}=${data[i]}&`;
    });
    let formateData = s.slice(0, -1);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/vendors?${formateData}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => {
          reject(error);
        });
    });
  }; 

  getVendorDetailsWithMobile = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vendors/getVendorWithMobile`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
export default Vendors;
